import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import showNotification from "../../commons/helpers/showNotification";
import yup from "../../commons/validators/customYup";
import { FormDateField } from "../../components/forms/FormikFields";
import SuperUserPrivateService from "../../services/SuperUserPrivateService";
import dayjs from "dayjs";

const schema = yup.object().shape({
  startDate: yup
    .date()
    .nullable()
    .max(yup.ref("endDate"), "Data Inicio não pode ser maior que Data Fim.")
    .required(),
  endDate: yup.date().nullable().required(),
});

const INIT_VALUES = {
  startDate: null,
  endDate: null,
};
// COMPONENT
function AdmDashboardNewAccountsPage() {
  const [myInitialValues, setMyInitialValues] = useState(INIT_VALUES);
  const [dataSource, setDataSource] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMyInitialValues({
      startDate: dayjs().subtract(30, "days"),
      endDate: dayjs(),
    });
  }, []);

  const myHandleSubmit = async (values, actions) => {
    try {
      setIsLoading(true);
      const resp = await SuperUserPrivateService.getNewAccounts({
        startDate: values.startDate.format("YYYY-MM-DD"),
        endDate: values.endDate.format("YYYY-MM-DD"),
      });

      if (resp.length === 0) {
        showNotification("warning", "Nenhum resultado encontrado.");
      }

      setDataSource(resp.reverse());
    } finally {
      actions.setSubmitting(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ s: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={myInitialValues}
            validationSchema={schema}
            onSubmit={myHandleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <FormDateField
                    fullWidth
                    label="Data Inicio"
                    name="startDate"
                  />
                  <Box sx={{ mr: 4 }} />
                  <FormDateField fullWidth label="Data Fim" name="endDate" />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    sx={{ ml: 2 }}
                  >
                    Buscar {isSubmitting && <CircularProgress />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>

        <Box sx={{ mt: 1 }}>
          {isLoading ? (
            <LinearProgress style={{ width: "100%" }} />
          ) : (
            <div style={{ height: "4px" }} />
          )}
        </Box>

        {dataSource?.length > 0 && (
          <Box sx={{ mt: 1, maxWidth: "400px" }}>
            <Typography
              component="span"
              variant="h5"
              sx={{
                m: "15px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              Quantidade de Novas Contas
            </Typography>
            <Divider />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Dia-Mês</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataSource?.map((c) => {
                  return (
                    <TableRow key={c.dateString}>
                      <TableCell>
                        {dayjs(c.dateString, "DD-MM").format("DD [de] MMMM")}
                      </TableCell>
                      <TableCell>{c.count}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </>
  );
}

export default AdmDashboardNewAccountsPage;
