import { Navigate } from "react-router-dom";
import DashboardLayout from "../components/layouts/DashboardLayout";
import MainLayout from "../components/layouts/MainLayout";

// INTERNAL EXAMPLES
import LandingPage from "../pages/examples/LandingPage";
// INTERNAL EXAMPLES

import EmployeeBasePage from "../pages/employee/EmployeeBasePage";
import EmployeeFormPage from "../pages/employee/EmployeeFormPage";
import EmployeeListPage from "../pages/employee/EmployeeListPage";
import FeatureBasePage from "../pages/feature/FeatureBasePage";
import FeatureFormPage from "../pages/feature/FeatureFormPage";
import FeatureListPage from "../pages/feature/FeatureListPage";
import ListUsersPage from "../pages/manageCompany/ListUsersPage";
import ManageCompanyBasePage from "../pages/manageCompany/ManageCompanyBasePage";
import ManageCompanyPage from "../pages/manageCompany/ManageCompanyPage";
import DefinePassword from "../pages/others/DefinePassword";
import Login from "../pages/others/Login";
import NotFound from "../pages/others/NotFound";
import ProfileBasePage from "../pages/profile/ProfileBasePage";
import ProfileEditPage from "../pages/profile/ProfileEditPage";
import AdmDashboardPage from "../pages/manager/AdmDashboardPage";
import AdmDashboardBasePage from "../pages/manager/AdmDashboardBasePage";
import AdmDashboardNewAccountsPage from "../pages/manager/AdmDashboardNewAccountsPage";
import AdmDashboardSubsPage from "../pages/manager/AdmDashboardSubsPage";

const routes = [
  {
    path: "app",
    element: <DashboardLayout />,
    children: [
      { path: "home-page", element: <LandingPage /> },
      {
        path: "manage-company",
        element: <ManageCompanyBasePage />,
        children: [
          {
            index: true,
            element: <ManageCompanyPage />,
          },
          {
            path: ":companyId",
            element: <ManageCompanyPage />,
          },
          {
            path: "list-users",
            element: <ListUsersPage />,
          },
        ],
      },
      {
        path: "employee",
        element: <EmployeeBasePage />,
        children: [
          {
            index: true,
            element: <EmployeeListPage />,
          },
          {
            path: ":id",
            element: <EmployeeFormPage />,
          },
          {
            path: "novo",
            element: <EmployeeFormPage />,
          },
        ],
      },
      {
        path: "adm",
        element: <AdmDashboardBasePage />,
        children: [
          {
            index: true,
            element: <AdmDashboardPage />,
          },
          {
            path: "new-accounts",
            element: <AdmDashboardNewAccountsPage />,
          },
          {
            path: "subs",
            element: <AdmDashboardSubsPage />,
          },
        ],
      },
      {
        path: "feature",
        element: <FeatureBasePage />,
        children: [
          {
            index: true,
            element: <FeatureListPage />,
          },
          {
            path: ":id",
            element: <FeatureFormPage />,
          },
          {
            path: "novo",
            element: <FeatureFormPage />,
          },
        ],
      },
      {
        path: "profile",
        element: <ProfileBasePage />,
        children: [
          {
            index: true,
            element: <ProfileEditPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <Login /> },
      { path: "define-password", element: <DefinePassword /> },
      { path: "404", element: <NotFound /> },
      { path: "/", element: <Navigate to="/app/home-page" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
